<script lang="ts" setup>
import { ref } from 'vue'
import type { Props } from './types'
import AppSyncRegisterForm from '@/components/App/SyncRegisterForm/index.vue'
import AppLoginForm from '@/components/App/LoginForm/index.vue'
import { useUserStore } from '@/stores/userStore'
import { getTriggerDate, getEntryNameAndEntryNameAlias } from '@/components/App/SyncRegisterForm/utils'
import { getDomainUserId } from '@/utils/getDomainUserId'

const { $config } = useNuxtApp()
const userStore = useUserStore()
const { locale } = useI18n()

const emit = defineEmits(['submit', 'sign-in'])

const props = withDefaults(defineProps<Props>(), {
  listingIds: () => [],
  listingId: '',
  screen: '',
  shouldEnqueueTwilioContact: true
})

const visibleForm = ref('sign-up-form')
const initialLoginStage = ref('')

const handleSignInEvent = (stage: string) => {
  visibleForm.value = 'sign-in-form'
  initialLoginStage.value = stage
}

const handleSignUpEvent = () => {
  visibleForm.value = 'sign-up-form'
}

const handleSubmitEvent = async (form: any, cdpId: string) => {
  if (props.shouldEnqueueTwilioContact) {
    props.listingIds.length > 0 || props.listingId ? enqueueTwilioContact(form, cdpId) : sendSlackAlert('Registration Form | Usuario se registró pero no tiene un match de listings en su búsqueda')
  }

  await userStore.getUser(cdpId)
  emit('submit', form)
}

const enqueueTwilioContact = async (form: any, cdpId: string) => {
  const triggerDate = getTriggerDate($config.public.environment)
  const flowPhone = locale.value === 'mx' ? $config.public.twilioOrchestratorFlowPhoneNumberMx : $config.public.twilioOrchestratorFlowPhoneNumberCo
  const listingIds = props.listingIds.length ? props.listingIds : [props.listingId]
  const { entryName, entryNameAlias } = getEntryNameAndEntryNameAlias(props.screen)

  if (form?.phone && cdpId) {
    const parameters = {
      entryName,
      listingIds,
      userId: cdpId,
      leadName: '',
      leadEmail: '',
      leadCountryCode: locale.value.toUpperCase(),
      domainUserId: getDomainUserId(),
      entryNameAlias
    }
    try {
      await $fetch('/api/buyer-front/twilio/enqueue-flow', {
        method: 'POST',
        body: {
          flowId: $config.public.twilioOrchestratorFlowId,
          flowPhone,
          userPhone: form.phone,
          triggerDate,
          parameters
        }
      })
    } catch (error) {
      useLogger().error({
        message: 'Error enqueuing for twilio flow'
      })
    }
  } else {
    sendSlackAlert('Registration Form | Usuario se registró pero no se pudo obtener el user id para encolar en twilio vacio', {
      formInfo: {
        ...form,
        cdpId
      }
    })
  }
}

const sendSlackAlert = (title: string, extraPayload = {}) => {
  $fetch('/api/buyer-front/send-slack-alert', {
    method: 'POST',
    body: {
      title,
      content: {
        url: window.location.href,
        ...extraPayload
      }
    }
  })
}

</script>

<script lang="ts">
export default {
  name: 'UserIdentityContainer'
}
</script>

<i18n src="./i18n.json" lang="json"></i18n>

<template>
  <div>
    <AppSyncRegisterForm
      v-if="visibleForm === 'sign-up-form'"
      :id="`${screen}-registration-modal-form`"
      :listing-id="listingId"
      :listing-ids
      :hub-code
      :screen
      :show-general-title
      :should-enqueue-twilio-contact
      @sign-in="handleSignInEvent"
      @submit="handleSubmitEvent" />

    <AppLoginForm
      v-else-if="visibleForm === 'sign-in-form'"
      :id="`${screen}-authentication-modal-form`"
      :listing-id="listingId"
      :screen="screen"
      :initial-stage="initialLoginStage"
      @sign-up="handleSignUpEvent"
      @submit="handleSubmitEvent" />
  </div>
</template>
