import { SCREEN } from '@/utils/segment'

export const getEntryNameAndEntryNameAlias = (screen: string) => {
  if (screen === SCREEN.PDP_INVESTORS) {
    return {
      entryName: 'QUOTATION',
      entryNameAlias: 'QUOTATION_REGISTERED_A'
    }
  } else {
    return {
      entryName: 'QUOTATION',
      entryNameAlias: 'QUOTATION_REGISTERED_B'
    }
  }
}

const getTriggerDateProduction = () => {
  const numDaysAhead = 1
  const currentDateColombia = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/Bogota' }))
  currentDateColombia.setDate(currentDateColombia.getDate() + numDaysAhead)
  const utcDate = new Date(Date.UTC(currentDateColombia.getFullYear(), currentDateColombia.getMonth(), currentDateColombia.getDate(), 14, currentDateColombia.getMinutes(), currentDateColombia.getSeconds()))
  return utcDate.toISOString().split('.')[0] + 'Z'
}

const getTriggerDateForTests = () => {
  const triggerDate = new Date()
  triggerDate.setMinutes(triggerDate.getMinutes() + 5)
  triggerDate.setSeconds(0)
  return triggerDate.toISOString().split('.')[0] + 'Z'
}

export const getTriggerDate = (environment: string) => {
  return environment === 'buyer-web-front-production' ? getTriggerDateProduction() : getTriggerDateForTests()
}
