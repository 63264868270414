<script lang="ts" setup>

import { reactive, ref, onMounted } from 'vue'
import type { $Fetch } from 'ofetch'
import RoomieButton from '@lahaus-roomie/roomie/src/components/RoomieButton/index.vue'
import RoomieOtpInput from '@lahaus-roomie/roomie/src/components/RoomieOtpInput/index.vue'
import RoomiePhoneInput from '@lahaus-roomie/roomie/src/components/RoomiePhoneInput/index.vue'
import { STATUS as INPUT_STATUS } from '@lahaus-roomie/roomie/src/components/RoomieInput/index.vue'
import RoomieLoader from '@lahaus-roomie/roomie/src/components/RoomieLoader/index.vue'
import ChatIcon from '@lahaus-roomie/roomie/src/assets/icons/chat.svg'
import { loadCaptchaScript, getCaptchaToken } from './utils'
import type { Props, PhoneProperties } from './types'

import { SCREEN_CTA } from '@/utils/segment'
import { useContactFormStore } from '@/stores/contactFormStore'
import LegalPolicies from '@/components/LegalPolicies/index.vue'
import { useUserStore } from '@/stores/userStore'

import AuthService from '@/services/authService'
import CdpService from '@/services/cdpService'

const { t, locale } = useI18n()
const { $config } = useNuxtApp()

const userStore = useUserStore()

const cdpService = new CdpService({
  apiFetch: globalThis.$fetch as $Fetch,
  cdpUrl: $config.public.cdpApiUrl as string,
  xApiKey: $config.public.cdpApiKey as string,
  xApplicationId: $config.public.environment
})

const emit = defineEmits(['submit', 'sign-up'])

const contactFormStore = useContactFormStore()

const siteKey = $config.public.googleRecaptchaSiteKey as string

const legalPoliciesAccepted = ref(contactFormStore.legalConsent)
const showWarningLegalPolicy = ref(false)

const otpValue = ref('')

const props = withDefaults(defineProps<Props>(), {
  listingIds: () => [],
  listingId: '',
  screen: '',
  initialStage: 'PHONE_FORM'
})

const formStage = ref(props.initialStage)

const form = reactive({
  phone: contactFormStore.userPhone,
  phone_number: '',
  phone_prefix: '',
  phone_is_valid: false
})

const title = t('title')

const authService = new AuthService({
  apiFetch: globalThis.$fetch as $Fetch,
  authUrl: $config.public.authorizationApiUrl as string,
  authId: $config.public.laHausAuthId as string,
  xApplicationId: $config.public.environment
})

const sendCodeFromExistingCustomer = async () => {
  form.phone_is_valid = true
  legalPoliciesAccepted.value = true

  const phoneMatch = form.phone.match(/(\+\d+)(\d{10})/)

  if (phoneMatch) {
    form.phone_prefix = phoneMatch[1]
    form.phone_number = phoneMatch[2]

    await sendOtpCode('SMS')
  } else {
    formStage.value = 'TECHNICAL_ERROR'
    sendSlackAlert('Login Form | No se pudo obtener el prefix y el number del phone', form)
  }
}

const handleFormSubmitSms = async () => {
  formStage.value = 'LOADING_FORM'
  contactFormStore.updateUserPhone(form.phone, false)

  const exists = await isUserExisting(form.phone)

  if (!exists) return handleSignUpEvent()

  await sendOtpCode('SMS')
}

const isUserExisting = async (phone: string) => {
  try {
    const user = await cdpService.getCustomerByPhone(phone, 1)
    return !!user.id
  } catch (error) {
    return false
  }
}

const sendSlackAlert = (title: string, extraPayload = {}) => {
  $fetch('/api/buyer-front/send-slack-alert', {
    method: 'POST',
    body: {
      title,
      content: {
        userPhone: form.phone,
        url: window.location.href,
        ...extraPayload
      }
    }
  })
}

const getPhoneProperties = (value:PhoneProperties) => {
  form.phone_number = value.phoneNumber
  form.phone_prefix = value.code
}

const otpButtonEnabled = ref(false)
const otpInputStaus = ref(INPUT_STATUS.default)
const otpInputStausMsg = ref('')

const otpResendTimer = ref(0)
const otpEnableResend = ref(true)

const timeUp = () => {
  otpEnableResend.value = true
}

const filledOtp = () => {
  otpButtonEnabled.value = true
  otpInputStaus.value = INPUT_STATUS.default
}

const handleOtpSubmit = async () => {
  try {
    formStage.value = 'LOADING_FORM'
    const captchaToken = await getCaptchaToken(siteKey)
    const result = await authService.challenge(otpValue.value, captchaToken)

    if (result?.auth_result) {
      const userId = userStore.setSession(result)

      // @ts-ignore
      window.dataLayer?.push({
        event: 'user-authentication-requested',
        screen: props.screen
      })

      emit('submit', form, userId)
    } else {
      formStage.value = 'TECHNICAL_ERROR'
      otpInputStaus.value = INPUT_STATUS.error
      sendSlackAlert('Login Form | Verificación de OTP no tiene tokens de acceso', result)
    }
  } catch (error: any) {
    otpInputStaus.value = INPUT_STATUS.error
    if (['invalid_code'].includes(error?.code)) {
      formStage.value = 'OTP_FORM'
      otpInputStausMsg.value = t(`errors.${error?.code}`)
    } else {
      formStage.value = 'TECHNICAL_ERROR'
      sendSlackAlert('Login Form | Verificación de OTP fallo', {
        error,
        message: error.message
      })
    }
  }
}

const resendOtpWhatsApp = async () => {
  otpResendTimer.value = 60
  otpEnableResend.value = false
  form.phone_is_valid = true
  legalPoliciesAccepted.value = true

  const phoneMatch = form.phone.match(/(\+\d+)(\d{10})/)

  if (phoneMatch) {
    form.phone_prefix = phoneMatch[1]
    form.phone_number = phoneMatch[2]

    await sendOtpCode('WHATSAPP')
  } else {
    formStage.value = 'TECHNICAL_ERROR'
    sendSlackAlert('Login Form | No se pudo obtener el prefix y el number del phone', form)
  }
}

const resendOtpSms = async () => {
  otpResendTimer.value = 60
  otpEnableResend.value = false
  form.phone_is_valid = true
  legalPoliciesAccepted.value = true

  const phoneMatch = form.phone.match(/(\+\d+)(\d{10})/)

  if (phoneMatch) {
    form.phone_prefix = phoneMatch[1]
    form.phone_number = phoneMatch[2]

    await sendOtpCode('SMS')
  } else {
    formStage.value = 'TECHNICAL_ERROR'
    sendSlackAlert('Login Form | No se pudo obtener el prefix y el number del phone', form)
  }
}

const handleSignUpEvent = () => {
  emit('sign-up', form)
}

const sendOtpCode = async (contactMedia = 'SMS') => {
  const isPhoneValid = form.phone_is_valid

  if (!isPhoneValid) {
    formStage.value = 'PHONE_FORM'
    return
  }

  if (!legalPoliciesAccepted.value) {
    showWarningLegalPolicy.value = true
    formStage.value = 'PHONE_FORM'
    return
  }

  showWarningLegalPolicy.value = false

  formStage.value = 'LOADING_FORM'

  try {
    await authService.authorizerToken()

    const captchaToken = await getCaptchaToken(siteKey)

    const result = await authService.authorizer(form.phone_prefix, form.phone_number, captchaToken, contactMedia)

    if (result?.challenge && result?.challenge === 'confirm_otp') {
      formStage.value = 'OTP_FORM'
    } else {
      formStage.value = 'TECHNICAL_ERROR'
      sendSlackAlert('Login Form | El resultado del authorizer no fue confirm_otp', result)
    }
  } catch (error: any) {
    formStage.value = 'TECHNICAL_ERROR'
    sendSlackAlert('Login Form | Error enviando el OTP', {
      error,
      message: error.message
    })
  }
}

onMounted(() => {
  loadCaptchaScript(siteKey)
})
</script>

<script lang="ts">
export default {
  name: 'LoginForm'
}
</script>

<template>
  <div class="login-form">
    <template v-if="formStage === 'PHONE_FORM'">
      <h2
        v-sanitize.basic="title"
        class="text-18 font-medium mt-8 text-carbon-800" />

      <p class="text-14 text-carbon-300">
        {{ t('description') }}
      </p>

      <form
        class="mt-24 flex flex-col"
        @submit.prevent="handleFormSubmitSms">
        <RoomiePhoneInput
          id="lead-form-phone"
          name="lead-form-phone"
          class="mb-16"
          :initial-country="locale"
          number-label="Número*"
          country-label="País"
          :is-required="true"
          :value="form.phone"
          @input="(newValue: string) => form.phone = newValue"
          @is-valid="(isValid: boolean) => form.phone_is_valid = isValid"
          @full="(value: PhoneProperties) => getPhoneProperties(value)" />

        <LegalPolicies
          v-if="!contactFormStore.legalConsent"
          v-model="legalPoliciesAccepted"
          :phone="form.phone"
          :show-warning="showWarningLegalPolicy"
          :screen="screen"
          :screen-cta="SCREEN_CTA.INVESTMENT_REGISTRATION_FORM" />

        <RoomieButton
          :id="`${id}-submit-button`"
          class="w-full gap-8 font-medium"
          :data-lh-id="`${id}-submit-button`"
          type="submit"
          :aria-label="t('cta')">
          <span class="flex">
            <ChatIcon class="h-24 w-24 fill-white mr-8" />
            {{ t('cta') }}
          </span>
        </RoomieButton>
      </form>

      <div class="flex flex-col mt-32 gap-8">
        <div class="divider-text">
          <p>
            {{ t('register') }}
          </p>
        </div>

        <RoomieButton
          :id="`${id}-register-button`"
          class="w-full gap-8 font-medium"
          :data-lh-id="`${id}-register-button`"
          variant="outlined"
          :aria-label="t('registerCta')"
          @click="handleSignUpEvent">
          <span class="flex">
            {{ t('registerCta') }}
          </span>
        </RoomieButton>
      </div>
    </template>

    <div
      v-else-if="formStage === 'OTP_FORM'"
      class="flex flex-col gap-42">
      <div>
        <h2
          v-sanitize.basic="t('otpTitle')"
          class="text-18 font-medium mt-8 text-carbon-800" />

        <p
          v-sanitize.basic="t('otpDescription', { lastDigits: form.phone.slice(-2) })"
          class="text-14 text-carbon-300" />
      </div>

      <RoomieOtpInput
        id="roomie-input-otp"
        v-model="otpValue"
        :status="otpInputStaus"
        :helper-text="otpInputStausMsg"
        :time-limit="otpResendTimer"
        @time-up="timeUp"
        @filled="filledOtp" />

      <div
        v-if="otpEnableResend === true"
        class="flex flex-col gap-8">
        <p
          class="text-14 text-carbon-300">
          {{ t('otpResend') }}
        </p>

        <a
          data-lh-id="login-form-otp-resend-cta-sms"
          class="text-primary-600 underline cursor-pointer text-14"
          @click="resendOtpSms">
          {{ t('otpResendCtaSms') }}
        </a>

        <a
          data-lh-id="login-form-otp-resend-cta-whastapp"
          class="text-primary-600 underline cursor-pointer text-14"
          @click="resendOtpWhatsApp">
          {{ t('otpResendCtaWhastapp') }}
        </a>
      </div>

      <div v-else>
        <p class="text-14 text-carbon-300">
          {{ t('otpResend') }}
        </p>

        <p class="text-14">
          {{ t('otpResendWaiting') }}
        </p>
      </div>

      <div>
        <RoomieButton
          :id="`${id}-otp-button`"
          class="w-full font-medium"
          :data-lh-id="`${id}-otp-button`"
          :aria-label="t('otpCta')"
          :disabled="!otpButtonEnabled"
          @click="handleOtpSubmit">
          <span class="flex">
            {{ t('otpCta') }}
          </span>
        </RoomieButton>

        <a
          id="login-form-go-back-cta"
          class="block text-primary-600 underline cursor-pointer text-center mt-16"
          data-lh-id="login-form-go-back-cta"
          @click="formStage = 'PHONE_FORM'">
          {{ t('otpGoBackCta') }}
        </a>
      </div>
    </div>

    <div
      v-if="formStage === 'LOADING_FORM'"
      class="h-[337px] flex items-center justify-center">
      <RoomieLoader
        size="md"
        color="primary" />
    </div>

    <div
      v-if="formStage === 'EXISTING_CUSTOMER_PHONE'"
      id="existing-customer-phone-form"
      class="md:h-[337px] flex flex-col items-center">
      <img
        src="https://lahaus-roomie.imgix.net/roomie/pictograms/alert.svg"
        width="96"
        height="96"
        alt="Alert">

      <div class="flex flex-col items-center justify-center">
        <p
          v-sanitize="t('existingPhoneTitle', { phone: form.phone })"
          class="text-primary-800 text-24 mb-24 text-center" />

        <p class="text-carbon-300 text-14 mb-24 text-center">
          {{ t('existingPhoneDescription') }}
        </p>

        <RoomieButton
          :id="`${id}-existing-submit-button`"
          class="w-full gap-8 font-medium"
          :data-lh-id="`${id}-existing-submit-button`"
          type="submit"
          :aria-label="t('cta')"
          @click="sendCodeFromExistingCustomer">
          <span class="flex">
            {{ t('existingPhoneCta') }}
          </span>
        </RoomieButton>

        <a
          id="login-form-go-back-cta"
          class="block text-primary-600 underline cursor-pointer text-center mt-16"
          data-lh-id="login-form-go-back-cta"
          @click="handleSignUpEvent">
          {{ t('otpGoBackCta') }}
        </a>
      </div>
    </div>

    <div
      v-if="formStage === 'TECHNICAL_ERROR'"
      id="technical-error-form"
      class="md:h-[314px] flex flex-col items-center">
      <img
        class="mb-24"
        src="https://lahaus-roomie.imgix.net/roomie/pictograms/tools.svg"
        width="96"
        height="96"
        alt="Alert">

      <div class="flex flex-col items-center justify-center">
        <p
          v-sanitize="t('technicalErrorTitle')"
          class="text-primary-800 font-bold text-24 mb-24 text-center" />

        <p class="text-carbon-300 text-14 mb-24 text-center">
          {{ t('technicalErrorDescription') }}
        </p>

        <RoomieButton
          :id="`${id}-error-start-button`"
          class="w-full gap-8 font-medium"
          :data-lh-id="`${id}-error-start-button`"
          type="submit"
          :aria-label="t('technicalErrorCta')"
          @click="formStage = 'PHONE_FORM'">
          <span class="flex">
            {{ t('technicalErrorCta') }}
          </span>
        </RoomieButton>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.login-form {
  .roomie-button.roomie-button--filled {
    &:disabled {
      @apply bg-carbon-light-50 text-carbon-light-600 opacity-50;

      border: 1px solid #919E9B;

      svg {
        @apply fill-carbon-light-600;
      }
    }
  }

  .roomie-otp-input__helper-text {
    margin-top: 0;
  }

  @media(max-width: theme('screens.sm')) {
    .roomie-otp-input__digit {
      width: 4.2rem;
      padding: 0.3rem;
    }
  }
}

.divider-text {
  @apply flex items-center w-full gap-16;

  p {
    @apply whitespace-nowrap;
  }

  &::before,
  &::after {
    @apply inline-block bg-carbon-light-300;

    content: '';
    width: 100%;
    height: 1px;
  }
}
</style>

<i18n src="./i18n.json" lang="json"></i18n>
